//***************************************************************************
//* Omninexus Sdn Bhd 2024.
//* All Rights Reserved.
//****************************************************************************
//* Filename  : _language-selector.scss.jsx
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (18 July 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign         Description
//* ------------ ----------   ------------------------------------------------
//* 20240718     Jecyine      create _language-selector.scss
//* 20240903     Glynnis      revamp UI
//
//***************************************************************************/
.language-selector {
  width: 100%;
  display: flex;
  justify-content: end;
  
  .custom-dropdown {
    position: relative;
    display: inline-block;
    font-family: Arial, sans-serif;
  }

  .selected-language {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    cursor: pointer;
    // border: 1px solid #271C4D;
    // background-color: rgba(38, 27, 74, 0.5);
    border-radius: 4px;
  }

  .flag-icon {
    width: 25px;
    height: 25px;
    margin-right: 10px;
  }

  // .selected-language span {
  //   margin-right: 5px;
  // }

  // .selected-language i {
  //   margin-left: auto;
  // }

  .dropdown-options {
    position: absolute;
    top: calc(100% + 5px);
    left: 0;
    right: 0;
    background-image: $bg-dropdown;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border: 1px solid #271C4D;
    border-radius: 4px;
    z-index: 2;
    // display: none;
  }

  .dropdown-option {
    display: flex;
    align-items: center;
    padding: 8px;
    cursor: pointer;
  }

  .dropdown-option:hover,
  .dropdown-option.active {
    background-image: $bg-dropdown;
  }

  // .custom-dropdown.open .dropdown-options {
  //   display: block;
  // }

}