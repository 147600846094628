//* All Rights Reserved.
//****************************************************************************
//* Filename  : _feedback-modal.scss
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (05 August 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign         Description
//* ------------ ----------   ------------------------------------------------
//* 20240805     Jecyline     create _feedback-modal.scss for feedback module
//* 20240808     Jecyline     add transition when hover and checked ratings
//
//***************************************************************************/
.rating-checkboxes {
  display: flex;
  justify-content: center;
  gap: 8px;
}

.rating-checkbox {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 34px;
  line-height: 30px;
  text-align: center;
  border-radius: 50%;
  color: white;
  cursor: pointer;
  font-weight: bold;

  // Define colors for each checkbox
  &.rating-1 { background-color: #ff7575; }
  &.rating-2 { background-color: #f9be04; }
  &.rating-3 { background-color: #f9be04; }
  &.rating-4 { background-color: #77ce1e; }
  &.rating-5 { background-color: #77ce1e; }

  // Style for the hidden checkbox input
  input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;

    &:checked + span {
      background-color: white;//black;
      color: #667EEA;//white;
      transition: color 0.3s ease-in-out;
    }
  }

  border: 3px solid transparent;
  transition: border-color 0.3s ease-in-out;

  &:hover {
    border-color: #667EEA;
    transition: color 0.3s ease-in-out;
  }

  &.checked {
    background-color: white;//black;
    color: #667EEA;//white;
    border-color: #667EEA;
  }
}
