//***************************************************************************
//* Omninexus Sdn Bhd 2024.
//* All Rights Reserved.
//****************************************************************************
//* Filename  : _loader.scss
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (25 July 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign         Description
//* ------------ ----------   ------------------------------------------------
//* 20240725     Jecyline      create _loader.scss
//* 20240821     Jecyline      add lottie-gradient-loader and lottie-dot-loader
//
//***************************************************************************/
.dot-loader {
  display: flex;
  justify-content: start;
  align-items: center;
  height: 8vh;

  .dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #555afd;
    margin: 0 2px;
    animation: jump-animation 1.2s infinite;

    &:nth-child(2) {
      animation-delay: 0.2s;
      background-color: #8c8ee3;
    }

    &:nth-child(3) {
      animation-delay: 0.4s;
      background-color: #8644ff;

    }

    &:nth-child(4) {
      animation-delay: 0.6s;
      background-color: #a58dd1;

    }

    &:nth-child(5) {
      animation-delay: 0.8s;
      background-color: #946ddd;

    }
  }

  @keyframes jump-animation {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-8px);
    }
  }
}

.lottie-gradient-loader {
  position: relative;
  width: 100px;
  height: 100px;
}

.lottie-dot-loader {
  position: relative;
  display: flex;
  text-align: start;
  // justify-content: start;
  // margin-left: -10px;
  height: 50px;
  width: 150px;
  transform: scale(1.2);
  margin-left: -5px;
}

// .lottie-dot-loader {
//   display: flex;
//   justify-content: start;
//   align-items: center;
//   height: 1vh;

//   .dotc {
//     position: relative;
//     width: 8px;
//     height: 8px;
//     margin: 0;
//   }
// }