//***************************************************************************
//* Omninexus Sdn Bhd 2024.
//* All Rights Reserved.
//****************************************************************************
//* Filename  : _affiliate.scss
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (15 July 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign         Description
//* ------------ ----------   ------------------------------------------------
//* 20240715     Jecyline     create _affiliate.scss
//
//***************************************************************************/
.affiliate {
  h1.card-title {
    font-size: $font-size-36;
    @include respond-below(custom575) {
      font-size: $font-size-26;
    }
  }
  .card-subtitle {
    font-size: $font-size-16;
  }
  .text-container {
    padding: 25px 0;
    @include respond-below(custom575) {
      padding: 15px 10px;
    }
  }
  .image-container {
    width: 250px;
    height: 100%;
    @include respond-below(custom575) {
      display: none;
    }
  }
  .image-container img{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .qr-image-container {
    max-width: 250px;
    max-height: 250px;
    margin: auto;
  }
  .qr-image-container img{
    aspect-ratio: 1/1;
    object-fit: contain;
  }

  .qr-image-container svg{
    max-width:100%;
  }
  .btn-primary.affiliate-btn {
    background: linear-gradient(to right, #F965FD 0%, #7151DF 32%, #271956 100%);
    border: 0;
    display: block;
    font-size: $font-size-16;
    @include rounded(20px);
    @include margin-padding(null,  8px 34px);
  }

  .referral-link-container {
    max-width: 400px;
    margin: auto;
    border-radius: 8px;
    border: 1px solid gray;

  }

  .referral-container {
    display: flex;
    align-items: center;
    background-color: #f9f9f9;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  .referral-link {
    flex: 1;
    padding: 10px;
    border: none;
    margin-right: 10px;
    background: transparent;
    color: #f9f9f9;
    text-align: center;
    cursor: text;
  }
  
  .copy-button {
    background: transparent;
    border: none;
    padding: 10px;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .copy-button:hover {
    color: $primary;
  }
  
  .copy-message {
    margin-left: 10px;
    color: #28a745;
  }
  
  .referral-table {
    width: 100%;
    margin-top: 1rem;
  }
  
  .referral-table thead th {
    background-color: #F0F0F0 !important;
    @include respond-below(custom575) {
      white-space:normal;
      vertical-align: middle;
      text-align: center;  
    }
  }
}

.model-content
{
  background-color: #24263B;
}