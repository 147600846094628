//* Filename  : _campaign-summary.scss
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (01 Oct 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign         Description
//* ------------ ----------   ------------------------------------------------
//* 20241001     Jecyline     Add _campaign-summary.scss
//
//***************************************************************************/
.file-wrap {
    border: 0px solid $secondary-100;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    position: relative;
    width: 100%;
    &.file-sidebar-toggle{
        .file-sidebar{
            display: none;
            @include respond-below(custom991) {
                left: 0;
                display: block;
            }
        }
        .file-cont-wrap{
            margin: 0;
        }
    }
    .file-sidebar {
        border-right: 1px solid $secondary-100;
        background-color: #24263B;
        z-index: 5;
        width: 300px;
        @include position(absolute,null,null,null,0);
        @include transition(all 0.3s ease);
        @include respond-below(custom991) {
            @include position(absolute,null,null,null,-331px);
        }
        .file-header {
            background-color: #24263B;
            border-bottom: 0px solid $secondary-100;
            color: $offwhite;
            height: 72px;
            @include margin-padding(null, 0 15px);
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            align-items: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            justify-content: space-between;
            -webkit-justify-content: space-between;
            -ms-flex-pack: space-between;
            span {
                font-size: $font-size-20;
                font-weight: $font-weight-semibold;
                text-transform: capitalize;
            }
            .file-side-close {
                display: none;
                background-color: $light-600;
                border: 1px solid $light-900;
                border-radius: 24px;
                color: $offwhite;
                font-size: $font-size-14;
                line-height: 24px;
                min-height: 26px;
                text-align: center;
                width: 26px;
                @include position(absolute,null,15px,null,null);
                @include respond-below(custom991) {
                    display: block; 
                }
            }
        }
        .file-search {
            background-color: #24263B;
            border-bottom: 0px solid $secondary-100;
            @include margin-padding(null, 10px 15px);
            width:100%;
            .input-group {
                width:100%;
                .form-control {
                    background-color: $white;
                    @include rounded(50px);
                    padding-left: 36px;
                }
                .input-group-text {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    align-items: center;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    color: $offwhite;
                    pointer-events: none;
                    z-index: 4;
                    @include position(absolute,0,null,0,0);
                    border: 0;
                    background: transparent;
                }
            }
        }
        .file-pro-list {
            background-color: #24263B;
            @include margin-padding(null, 15px);
            .file-scroll {
                max-height: calc(100vh - 259px);
                min-height: 497px;
                .file-menu {
                  background-color: #24263B;
                    display: inline-block;
                    @include margin-padding(0 0 10px, 0);
                    width:100%;
                    li {
                        display: inline-block;
                        width:100%;
                        &.active,&:hover{
                            background: rgba(33, 33, 33, 0.5);
                        }
                        a {
                            color: $offwhite;
                            display: inline-block;
                            width:100%;
                            text-transform: capitalize;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            @include margin-padding(null, 10px 15px);
                            @include transition(all 0.2s ease);
                        }
                        + li {
                            margin-top: 2px;
                        }
                    }
                }
                .show-more {
                    text-align: center;
                    a {
                        position: relative;
                        @include margin-padding(null, 0 20px 0 15px);
                        &:before {
                            content: '\f107';
                            font-size: $font-size-18;
                            line-height: inherit;
                            font-family: 'FontAwesome';
                            @include transform(translateY(-50%));
                            @include position(absolute,50%,0,null,null);
                        }
                    }
                }
            }
        }
    }
}
.file-cont-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-left: 300px;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 100%;
    @include transition(all 0.3s ease);
    @include respond-below(custom991) {
        margin:0;
    }
    .file-cont-inner {
        flex: 0 0 100%;
        // width: 100%;
        max-width:100%;
        .file-cont-header {
            background: #24263B;
            border-bottom: 0px solid $secondary-100;
            height: 72px;
            width: 100%;
            @include margin-padding(null, 0 15px);
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            align-items: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            justify-content: space-between;
            -webkit-justify-content: space-between;
            -ms-flex-pack: space-between;
            .file-options {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                a{
                    color: $offwhite;
                }
            }
            span {
                color: $offwhite;
                font-size: $font-size-20;
                font-weight: $font-weight-semibold;
            }
            .file-options {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
            }
            .btn-file{
                display: inline-block;
                overflow: hidden;
                position: relative;
                vertical-align: middle;
                input {
                    cursor: pointer;
                    direction: ltr;
                    filter: alpha(opacity=0);
                    font-size: $font-size-23;
                    height: 100%;
                    margin: 0;
                    opacity: 0;
                    @include position(absolute,0,0,null,null);
                    width:100%;
                }
            }
        }
    }
}
.file-content {
    flex: 0 0 100%;
    max-width:100%;
    .file-search {
        background-color: $light-300;
        border-bottom: 1px solid $secondary-100;
        width:100%;
        @include margin-padding(null, 10px 15px);
        .input-group-text {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            align-items: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            bottom: 0;
            color: $offwhite;
            pointer-events: none;
            z-index: 4;
            @include position(absolute,0,null,null,0px);
            border: 0;
            background: transparent;
        }
        .form-control {
            background-color: $white;
            padding-left: 36px;
            @include rounded(50px);
        }
    }
    .campaign-body {
      background-color: #24263B;
      .campaign-scroll {
        max-height: calc(100vh - 259px);
        min-height: calc(497px + 94px);
        overflow-y: auto;
        .campaign-content-inner {
          @include margin-padding(null, 15px);
          width:100%;
          .campaign-header {
            margin-bottom: 15px;
            padding: 10px;
            display: flex;
            justify-content: space-between;
            .title {
              font-size: 16px;
              font-weight: bold;
              margin-bottom: 5px;
              color: $offwhite;
            }
            .desc {
              font-size: 14px;
              margin-bottom: 5px;
              color: $offwhite;
            }
          }
          @include respond-below(custom1199) {
            .campaign-header {
              display: block;
            }
          }
          @include respond-below(custom767) {
            padding: 0;
            .campaign-header {
              padding: 8px;
              .title {
                font-size: 14px;
              }
              .desc {
                font-size: 13px;
              }
            }
          }
          .campaign-content {
            padding: 10px;
            background-color: #fff;
            border-radius: 4px;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Slight shadow for better focus */
            .title {
              padding: 10px;
              font-size: 18px;
              font-weight: bold;
              margin-bottom: 5px;
              color: $title-color;
            }
            .campaign-content-img {
              margin-bottom: 15px;
              text-align: center;
              margin-bottom: 15px;
              padding: 10px;
              .image-container{
                max-width: 60%;
                margin: auto;
                height: auto;
                border-radius: 4px;
                box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
              }
            }
            .campaign-content-copywriting {
              margin-bottom: 15px;
              padding: 15px;
              color: #555;
              h4 {
                  font-weight: bold;
                  color: $title-color;
                  padding: 5px;
              }
            }
            //brand boost container :start
            .campaign-content-brandboost {
              margin-bottom: 15px;
              color: #555;
              .brandboost-container {
                background-image: linear-gradient(to right, #7585F1, #DB25DF, #7151DF);
                border-radius: 5px;
                padding: 8px;
                margin: 8px;

                .brandboost-box {
                  border-radius: 5px;
                  max-width: 100%;
                  padding: 25px;
                  background-color: #fff;
                  margin: 3px;
                  .keyword-img {
                    width: 100%;
                    max-width: 300px;
                    height: auto;
                    object-fit: contain;
                    margin-top: 1rem;
                  }
                  
                  p, span {
                      color: #333;
                  }  
                  .summary {
                    line-height: 1.2;
                  }
                  li {
                      margin-bottom: 0;
                  }
                  .underline{
                      text-decoration: underline;
                  }
                  .text-bold{
                      font-weight: $font-weight-semibold;
                  }
                }
              }
            }
            //brand boost container :end
            .campaign-content-button {
              margin-bottom: 15px;
              text-align: center;
            }
          }
          @include respond-below(custom767) {
            .campaign-content {
              padding: 8px;
              .title {
                padding: 8px;
                font-size: 16px;
              }
              .campaign-content-copywriting {
                margin: 8px;
                padding: 8px;
              }
              .campaign-content-img .image-container {
                max-width: 80%;
              }
            }
          }
        }
      }
    }
}